import http from "../http-common";

export default class LoginService {

  login(wachtwoord) {
    let formData = new FormData();
    formData.append("password", wachtwoord);

    return http.post("/login.php", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
}
