import http from "../http-common";

export default class UploadService {

  uploadSingleBatch(file, apiKey, maand) {
    let formData = new FormData();
    file.forEach(element => {
        formData.append("file[]", element);
    });
    formData.append("key", apiKey);
    formData.append("maand", maand);
    return http.post("/uploadfiles.php", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  deleteFiles(maand, apiKey){
    let formData = new FormData();
    formData.append("key", apiKey);
    return http.post("/deleteFotosByMaand.php?maand=" + maand, formData);
  }
}
