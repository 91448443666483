import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import routes from './routes';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueMasonry from 'vue-masonry-css'



Vue.config.productionTip = false
Vue.use(VueRouter)

const router = new VueRouter({routes});


Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBlhjrvdXkVXMkvfw0aMTzCWDf9Y69r5J8',
    libraries: 'places',
  },
})

Vue.use(VueMasonry)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

