<template>
  <v-container class="center mt-10">
    <v-dialog v-model="dialog" width="500">
        <img
          :src="selectedImage"
          height="auto"
          max-width="100%"
          transition="slide-x-transition"
          style="border:5px solid white; border-radius: 4px"
        >
    </v-dialog>
    <br />

    <v-tabs center-active show-arrows grow v-model="activeMonthId" style="margin-right:8px !important;">
      <v-tab  
        v-for="(month, index) in months"
        :key="index"
        :disabled="!month.hasFotos"
        @click="selectMonth(index)"
      >{{month.name}}</v-tab>
    </v-tabs>
        <div class="image-grid mt-2">
          <div class="image-item" v-for="(file, index) in images"
              :key="index">
              <v-img :src="file" aspect-ratio="1" class="grey lighten-2" @click.stop="selectImageOverlay(file); dialog = true">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
          </div>
        </div>

    <div class="edit-wrapper">
        <v-btn text to="/upload" ><v-icon to="/">mdi-pencil</v-icon></v-btn>
    </div>
  </v-container>
</template>

<style>

    /* Grid Container */
    .image-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 10px; /* Space between images */
    }

    /* Grid Items (Images) */
    .image-grid img {
      width: 100%;
      height: 100%;
      object-fit: cover; /* Ensures the images are cropped to fit within the square */
      display: block;
    }

    .image-item {
      aspect-ratio: 1 / 1; /* Makes each item a square */
      overflow: hidden; /* Ensures no overflow */
    }

.center {
  width: 100%;
}

.edit-wrapper{
  display:flex;
  justify-content: flex-end;
}
</style>

<script>
import axios from "axios";
import FotoService from "../services/FotoService";

export default {
  name: "Fotos",
  data() {
    return {
      dialog: false,
      images: [],
      maandenMetFotos: new Array(),
      activeMonth: "",
      activeMonthId: 0,
      overlay: false,
      months: [
        { id: 0, name: "januari" , hasFotos: false},
        { id: 1, name: "februari" , hasFotos: false },
        { id: 2, name: "maart"  , hasFotos: false},
        { id: 3, name: "april"  , hasFotos: false},
        { id: 4, name: "mei"  , hasFotos: false},
        { id: 5, name: "juni"  , hasFotos: false},
        { id: 6, name: "juli"  , hasFotos: false},
        { id: 7, name: "augustus"  , hasFotos: false},
        { id: 8, name: "september"  , hasFotos: false},
        { id: 9, name: "oktober"  , hasFotos: false},
        { id: 10, name: "november"  , hasFotos: false},
        { id: 11, name: "december"  , hasFotos: false}
      ],
      numberOfColumns: Math.round(window.innerWidth / 300),
      selectedImage: "",
    };
  },
  watch: {
    overlay (val) {
      !val;
    }
    },
  mounted: function() {
    this.getMaandenMetFotos();
  },
  methods: {
    reset: function() {
      window.stop();
      this.images = new Array();
    },

    getActiveMonth: function() {
      return this.months[this.activeMonthId].name;
    },

    selectMonth: function(id) {
      this.reset();
      this.activeMonthId = id;
      this.activeMonth = this.getActiveMonth();
      this.getImages();
    },

    getImages: function() {
      var fotoService = new FotoService();
      fotoService.getFotosByMaand(this.activeMonth).then((response) => {
        this.images = response.data;
      });
    },

    selectImageOverlay: function(image) {
      this.selectedImage = image;
    },

    getMaandenMetFotos: function() {
      axios.get("https://kinderopvangtoermalijn.be/getMaandenMetFotos.php").then(
        response => {
          this.maandenMetFotos = response.data;
          this.setLastMonthAsActive();
          this.addInfoToMonthObjects();
          this.getImages();
        }
      );
    },

    setLastMonthAsActive: function() {
      var lastMonthName = this.maandenMetFotos[this.maandenMetFotos.length - 1];
      var monthObj = this.months.find(x => x.name == lastMonthName);
      this.activeMonth = monthObj.name;
      this.activeMonthId = monthObj.id;
    },

    addInfoToMonthObjects(){
      this.maandenMetFotos.forEach(maandMetFoto => {
          var monthObj = this.months.find(x => x.name === maandMetFoto);
          if(monthObj != null){
            monthObj.hasFotos = true;
          }
      });
    },
  }
};
</script>
