<template>
  <v-app>

    <!-- APPLICATION BAR -->
    <v-app-bar app fixed flat tile id="home">
      <v-toolbar-title>
          <div class="logo">Kinderopvang Toermalijn</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn :ripple="{ class: 'ripple-color' }" text to="/" @click="$vuetify.goTo('#home');isActive='home'" v-bind:class="{ active: isActive === 'home' }">Home</v-btn>
        <v-btn :ripple="{ class: 'ripple-color' }" text to="/" @click="$vuetify.goTo('#over');isActive='over'" v-bind:class="{ active: isActive === 'over' }">Over Toermalijn</v-btn>
        <v-btn :ripple="{ class: 'ripple-color' }" text to="/Fotos" @click="isActive='fotos'" v-bind:class="{ active: isActive === 'fotos' }">Foto's</v-btn>
        <v-btn :ripple="{ class: 'ripple-color' }" text to="/" @click="$vuetify.goTo('#contact'); isActive='contact'" v-bind:class="{ active: isActive === 'contact' }">Contact</v-btn>
      </v-toolbar-items>

    <v-menu
      transition="slide-y-transition"
      bottom v-if="$vuetify.breakpoint.smAndDown"
    >
      <template v-slot:activator="{ on }">
      <v-app-bar-nav-icon  color="black" @click.stop="drawer = !drawer" v-on="on">
        </v-app-bar-nav-icon>
      </template>
      <v-list dense class="menu-buttons-nav-bar">
        <v-list-item :ripple="{ class: 'ripple-color' }"  to="/" @click="$vuetify.goTo('#home');isActive='home'" v-bind:class="{ active: isActive === 'home' }">
          <v-list-item-content>
            <v-list-item-title >Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :ripple="{ class: 'ripple-color' }"  to="/" @click="$vuetify.goTo('#over');isActive='over'" v-bind:class="{ active: isActive === 'over' }">
          <v-list-item-content>
            <v-list-item-title >Over Toermalijn</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :ripple="{ class: 'ripple-color' }"  to="/Fotos" @click="isActive='fotos' " v-bind:class="{ active: isActive === 'fotos' }">
          <v-list-item-content>
            <v-list-item-title>Foto's</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :ripple="{ class: 'ripple-color' }"  to="/" @click="$vuetify.goTo('#contact'); isActive='contact'" v-bind:class="{ active: isActive === 'contact' }">
          <v-list-item-content>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
      
      
    </v-app-bar>
    <!-- END OF APPLICATION BAR -->

    <router-view></router-view>
  </v-app>
</template>

<style>
.v-app-bar {
  border-style: solid !important;
  border-width: 0px 0px 1px 0px !important;
  border-color: rgb(241, 243, 245) !important;
  background-color: rgba(255, 255, 255) !important;
}

@font-face {
    font-family: 'Antropos';
    src: url('/AntroposFreefont.eot');
    src:
        url('/antropos.ttf') format("truetype"), 
        url('/AntroposFreefont.eot?#iefix') format('embedded-opentype'),
        url('/AntroposFreefont.woff2') format('woff2'),
        url('/AntroposFreefont.woff') format('woff'),
        url('/AntroposFreefont.svg#AntroposFreefont') format('svg');
    font-weight: 900;
    font-style: normal;
}

.logo{ 
    font-family: "Antropos", Verdana, Tahoma;
    text-decoration: none !important;
    color: #B27C58;
    font-size: 1.2em;
}
.main-wrapper {
  height: 100vh;
  background-image: url("./assets/images/main_background_small.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.v-btn:before, .v-list-item:before{
  background-color:white !important;
}

.menu-buttons-nav-bar a{
  color:black !important;
}

.active, .v-item--active{
  color:black !important; 
}

.ripple-color{
  color:lightgray !important;
}

.content-center {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
  width: 700px !important;
  height: 90vh !important;
}

/* MOBILE */

@media only screen and (max-width: 600px) {
  .logo{
    font-size:0.9em;
  }
}

/* TABLET */
/* 
@media only screen and (max-width: 950px) and (min-width: 600px) {
  .content-center {
    width: 600px !important;
  }
} */
</style>

<script>
import { gmapApi } from "vue2-google-maps";


export default {
  name: "Home",
  props: {
    source: String,
  },
  computed: {
    google: gmapApi
  },
  data: () => ({
    drawer: false,
    isActive: '',
    timeline: [
      {
        hour: "8u00",
        icon: "mdi-bicycle",
        text:
          "We brengen met de Grote Bakfiets Joren & Thorben naar school. Vroege kindjes mogen mee, anderen worden onderweg opgepikt. De late kindjes komen na 9u, als we terug zijn."
      },
      {
        hour: "9u30",
        icon: "mdi-fruit-cherries",
        text: "We eten fruit. De kleintjes gaan slapen."
      },
      {
        hour: "11u00",
        icon: "mdi-silverware-spoon",
        text: "Groentenmaaltijd."
      },
      {
        hour: "12u00",
        icon: "mdi-sleep",
        text: "Alle kinderen gaan slapen."
      },
      {
        hour: "15u00",
        icon: "mdi-food-apple",
        text: "Tussendoortje/ fruitpap."
      },
      {
        hour: "16u00",
        icon: "mdi-handball",
        text: "Joren & Thorben ophalen / Buiten spelen."
      }
    ]
  }),
  methods: {
  }
};
</script>