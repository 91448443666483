<template>
  <v-container
    style="margin: 0px; padding: 0px; width: 100%; max-width: 100%; height: 100%;"
  >
    <v-content class="main-wrapper">
      <v-container class="main-title">
        <p>
          <span style="font-weight: bold">Kinderopvang Toermalijn</span>
        </p>
        <p>
          <span>biedt opvang voor jonge kinderen</span>
        </p>
        <p>
          <span>in een natuurlijke omgeving</span>
        </p>
        <p>
          <span>met biologische producten.</span>
        </p>
      </v-container>
      <v-container class="bottom-card-wrapper" white fluid>
        <v-col cols="12">
          <v-row
            align="center"
            justify="space-around"
            class="bottom-card-wrapper-row"
          >
            <v-card class="main-bottom-card" tile flat>
              <v-card-title class="justify-center bottom-card-title">
                <span class="text-accent" style="margin-right:5px;">
                  Natuurlijk
                </span>
                speelgoed
              </v-card-title>
              <v-card-text v-if="$vuetify.breakpoint.mdAndUp">
                Speelgoed van natuurlijk materialen, zoals wol en hout, voelen
                ECHT aan en bevorderen de fantasie en creativiteit.
              </v-card-text>
            </v-card>

            <v-card class="main-bottom-card" flat tile>
              <v-card-title class="justify-center bottom-card-title">
                <span class="text-accent" style="margin-right:5px;">
                  Herbruikbare
                </span>
                luiers
              </v-card-title>
              <v-card-text v-if="$vuetify.breakpoint.mdAndUp">
                Om de afvalberg zo klein mogelijk te houden, gebruiken we
                herbruikbare luiers. Luier- en potjesinhoud wordt gecomposteerd.
              </v-card-text>
            </v-card>

            <v-card class="main-bottom-card" flat tile>
              <v-card-title class="justify-center bottom-card-title">
                <span class="text-accent" style="margin-right:5px;">
                  Biologisch
                </span>
                en
                <br />
                <span
                  class="text-accent"
                  style="margin-right:5px; margin-left:5px;"
                >
                  seizoensgebonden
                </span>
                groenten en fruit
              </v-card-title>
              <v-card-text v-if="$vuetify.breakpoint.mdAndUp">
                Voor de gezondheid van je kind én die van onze aardbol, maken we
                zoveel mogelijk gebruik van biologische en seizoensgebonden
                groenten & fruit.
              </v-card-text>
            </v-card>
          </v-row>
          <v-row>
            <v-img
              src="./../assets/images/arrow-down.png"
              style="cursor: pointer;"
              @click="$vuetify.goTo('#over')"
              contain
              id="arrow_down"
            ></v-img>
          </v-row>
        </v-col>
      </v-container>
    </v-content>
    <!-- END OF CONTENT LANDING PAGE -->

    <v-container fluid pa-0>
      <v-container fluid pa-0>
        <v-row>
          <v-col cols="0" md="4" sm="0" class="pa-0 d-none d-md-flex d-lg-flex">
            <v-img src="./../assets/images/Main1.jpg" height="100%" />
          </v-col>
          <v-col
            cols="12"
            md="8"
            sm="12"
            id="over"
            class="d-flex align-center justify-center content-wrapper-vh"
          >
            <v-container class="content-wrapper-width">
              <h2>Over Toermalijn</h2>
              <br />
              <p>
                Hallo, ik ben Katrien Baaten, trotse mama van Joren en Thorben
                en liefje van m'n liefde, Vincent. Ik ben kleuterjuf van
                opleiding, maar in januari 2006 ben ik gestart met Toermalijn,
                een kinderopvang in Kasterlee die ik zoveel mogelijk op
                natuurlijke manier wil invullen. Als onthaalouder vang ik max 5
                kindjes op in ons huis in het bos.
              </p>
              <p>
                De kindjes spelen hier met speelgoed van natuurlijke materialen
                zoals wol en hout. Fruit en groenten zijn biologisch en
                seizoensgebonden en we proberen zoveel mogelijk naar buiten te
                gaan.
              </p>
              <p>
                Toermalijn is aangesloten bij Ferm Kinderopvang en werkt
                dus inkomensgerelateerd. Ons huishoudelijk reglement vindt u
                <a href="../Huishoudelijk_Reglement.pdf" download>hier</a>
                terug.
              </p>
              <p>
                Registratie bij Kinderopvang Toermalijn kan ook via
                opvang.vlaanderen en via Ferm Kinderopvang.
              </p>

              <h2>Openingsdagen/-uren</h2>
              <br>
              <p>
                Toermalijn is open op alle weekdagen van 7u30 tot 17u30, behalve
                op woensdag.
              </p>
            </v-container>
          </v-col>

          <v-col
            cols="12"
            md="8"
            sm="12"
            class="d-flex align-center justify-center content-wrapper-vh"
          >
            <v-container class="content-wrapper-width">
              <h2>Dagritme</h2>
              <br />
              <p>
                Wij stimuleren ouders om hun kindjes niet te snel naar de
                kinderopvang te sturen. Wij raden de leeftijd van zes maanden of
                ouder aan omdat we het in onze pedagogische visie heel
                belangrijk vinden dat baby's een hechte band kunnen opbouwen met
                hun eigen (pleeg)moeder(s), (pleeg)vader(s). Voor zes maanden
                zijn ze nog zo fragiel en vaak hulpeloos. Een stevig en
                beweeglijk lichaampje zal hun ook meer zelfvertrouwen geven in
                hun nieuwe omgeving. Uit ervaring hebben we ook geleerd dat vele
                (vnl. moeders) na drie maanden fysiek en emotioneel nog niet
                klaar zijn voor deze grote stap. Als opvang kunnen wij ook nooit
                hetzelfde broodnodige fysieke contact en dezelfde liefdevolle
                warmte geven als thuis. Jammer, maar dat is zo.
              </p>
              <p>
                Maar... langs de andere kant stimuleren we ouders ook om hun
                kindje niet té snel naar school te laten gaan, maar de overgang
                pas te maken als een kind er klaar voor is. Daarom reserveren we
                een plekje voor ieder kind bij ons tot (en met) de
                schoolvakantie vlak voor of vlak na hun derde verjaardag. Rond
                de leeftijd van 2 jaar bekijken we het samen. Bij deze keuze
                vinden we het heel belangrijk dat de kindjes zelfstandig en
                sterk genoeg in het leven staan om de grote stap naar school te
                maken. We kijken hier naar zindelijkheid, zelf aan- en
                uitkleden, eten, taal, nog behoefte heeft aan middagdutjes maar
                ook of een kindje motorisch klaar is, nood heeft aan samenspel,
                verveling kan overwinnen, taakjes kan uitvoeren.
              </p>
              <br />

              <h2>Luiers</h2>
              <br />
              <p>
                Bij Toermalijn kiezen we voor het gebruik van herbruikbare
                luiers. Dit geeft ons extra werk maar we hopen hiermee de
                afvalberg een beetje te verkleinen, ouders te inspireren en een
                mooie wereld te schenken aan de kindjes.
              </p>
              <p>
                De luiers zijn van Toermalijn, het gebruik, het wassen en de
                kosten hiervan zijn voor mezelf.
              </p>
            </v-container>
          </v-col>
          <v-col cols="0" md="4" sm="0" class="pa-0 d-none d-md-flex d-lg-flex">
            <v-img src="./../assets/images/Main2.jpg" height="100%" />
          </v-col>

          <v-col cols="0" md="4" sm="0" class="pa-0 d-none d-md-flex d-lg-flex">
            <v-img
              src="./../assets/images/Main3.jpg"
              height="100%"
              width="auto"
            />
          </v-col>
          <v-col
            cols="12"
            md="8"
            sm="12"
            class="d-flex align-center justify-center content-wrapper-vh"
          >
            <v-container class="content-wrapper-width">
              <h2>Wenmomenten</h2>
              <br />
              <p>
                Bij Toermalijn hechten we veel belang aan de verwelkoming van
                nieuwe kindjes en ouders. Beiden dienen zich bij ons thuis te
                voelen. Ouder(s) en kind worden op voorhand uitgenodigd om op
                bezoek te komen voor een kennismaking. Ze kunnen samen met hun
                kindje even wennen aan de nieuwe omgeving. Ook ik leer jullie en
                de gewoontes van het kindje hierdoor beter kennen.
              </p>
              <p>
                Op de eerste wendag kunnen de ouder(s) samen met hun kindje 's
                voormiddags mee komen draaien. Nadien bouwen we rustig de
                wendagen van het kindje alleen op. De eerste keer een uurtje
                zonder ouder(s), dan een voormiddag, dat tot na het
                middagslaapje,... Afhankelijk van het verloop laten we dit soms
                korter of langer duren. Dit gebeurt steeds in gezamenlijk
                overleg met de ouders.
              </p>
              <br />

              <h2>Voeding</h2>
              <br />
              <p>
                Dagelijks bereiden we verse vegetarische maaltijden met
                biologische ingrediënten.
              </p>
              <p>
                Rond 9u30 hebben we met de grootjes een fruitmoment, hierbij
                krijgen ze de keuze uit verschillende soorten fruit soms
                aangevuld met nootjes. Hierbij drinken we sap.
              </p>
              <p>Om 11u eten we een groentenmaaltijd en drinken we water.</p>
              <p>
                Rond 15u hebben we met de grootjes een thee-momentje met een
                gezond tussendoortje, de kleintjes krijgen verse fruitpapjes met
                granen.
              </p>
              <br />

              <h2>Slapen</h2>
              <br />
              <p>
                Bij Toermalijn hechten we veel belang aan het slapen, dit is een
                moment van verwerking en terug op krachten komen. We werken met
                een vast slaapritme. De kleintjes slapen ongeveer van 9u tot
                10u30 en van 12u30 tot 14u30. De grootjes slapen samen met de
                kleintjes van 12u30 tot 14u30.
              </p>
              <p>
                Om de overgang vlot te laten verlopen, is het fijn dat er, voor
                de start, thuis naar dit ritme toe gewerkt wordt. Wees je er als
                ouder van bewust , dat zelfstandig slapengaan van belang is in
                een (grote) groep kinderen. Voor mij als onthaalouder, is het
                fijn dat alle kinderen 's namiddags op hetzelfde moment slapen,
                om even een moment van pauze te hebben; een moment om alles
                terug op orde te zetten en zelf even tot rust te komen. Daar
                hebben ikzelf én de kinderen baat bij.
              </p>
            </v-container>
          </v-col>
          <v-col
            cols="12"
            md="8"
            sm="12"
            class="d-flex align-center justify-center content-wrapper-vh"
          >
            <v-container class="content-wrapper-width">
              
              <h2>Spel en speelgoed</h2>
              <br>
              <p>
                Een belangrijk uitgangspunt met betrekking tot spelen is dat wij
                de kinderen niet animeren, maar ze begeleiden in hun
                activiteiten en spel. Soms duurt het bij een kind wat langer om
                uit eigen kracht tot spel te komen en gaat er een fase van
                gezonde verveling aan vooraf. In tegenstelling tot de meeste
                opvattingen zien wij een positief element in deze momenten van
                verveling. Zo ontwikkelen de kinderen namelijk het vermogen uit
                eigen kracht tot spel te komen. Dit is een eigenschap die zich
                later zal manifesteren als initiatiefkracht en een sterke wil.
              </p>
              <p>
                Bij de huishoudelijke taken proberen we de kinderen te betrekken
                door hen uit te nodigen om in dit plezier te delen. De was
                vouwen, de groenten snijden,... worden al snel met veel liefde
                door de kinderen meegedaan.
              </p>
              <p>
                Muziek brengen we door zelf te zingen met de kinderen en door
                zelf instrumenten te bespelen. Er worden liedjes en versjes
                gedaan, maar ook voor het eten, bij het opruimen, het naar
                buiten gaan, bij het wassen van de handen, het vouwen van de
                was,...
              </p>
              <p>
                Ons speelgoed is zo veel mogelijk van natuurlijke materialen en
                is niet overdadig aanwezig. We kiezen ervoor om niet te veel
                speelgoed te hebben zodat er nog ruimte is om te spelen en zodat
                de kinderen vanuit hun eigen creativiteit tot spelen kunnen
                komen.
              </p>
              <p>
                In de speelruimte proberen we een vreugdevolle stemming te
                handhaven, met voldoende spelmogelijkheid, maar ook steeds met
                de noodzakelijke rust, waarin kinderen zo goed gedijen. Wij
                zetten geen radio of cd's aan, enkel gezang of de geluiden van
                de kinderen zelf zijn te horen in onze leefruimte.
              </p>
              <p>
                We wonen op een prachtige plek in het bos waar kinderen zich
                volop kunnen en mogen uitleven. Hou er dus rekening mee dat wij
                graag rollen in de modder en springen in de plassen. De
                kleertjes worden regelmatig goed vuil.
              </p> 
            </v-container>
          </v-col>
          <v-col cols="0" md="4" sm="0" class="pa-0 d-none d-md-flex d-lg-flex">
            <v-img src="./../assets/images/Main4.jpg" height="100%" />
          </v-col>
        </v-row>

        <v-container
          class="white justify-center align-center d-flex"
          width="100%"
          fluid
          id="contact"
          style="margin: 0px !important; padding: 0px !important;"
        >
          <v-container
            class="content-center d-flex justify-center align-center"
          >
            <v-row>
              <v-col cols="12" sm="4">
                <h2>Contact</h2>
                <br />
                <p>
                  <strong>Kinderopvang Toermalijn</strong>
                </p>
                <p style="margin-top:-15px;">Katrien Baaten</p>
                <p>Bos en Bremdreef 2</p>
                <p style="margin-top:-15px;">2460 Kasterlee</p>
                <p>+32 14 82 85 61</p>
                <p>
                  <a href="mailto:katrienbaaten@outlook.com">
                    katrienbaaten@outlook.com
                  </a>
                </p>
              </v-col>
              <v-col cols="12" sm="8">
                <GmapMap
                  :center="{ lat: 51.260317, lng: 5.026206 }"
                  :zoom="14"
                  map-type-id="terrain"
                  style="width: 100%; height: 260px"
                  :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false,
                  }"
                >
                  <GmapMarker
                    ref="marker"
                    :position="
                      google && new google.maps.LatLng(51.260317, 5.026206)
                    "
                  />
                </GmapMap>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-container>
    </v-container>
  </v-container>
</template>

<style>
.bottom-card-wrapper {
  position: absolute;
  bottom: 0;
  min-height: 30%;
}

.main-bottom-card {
  width: 400px !important;
  text-align: center;
  height: auto !important;
}
.bottom-card-title {
  font-size: 1.5em !important;
}

.content-wrapper-vh {
  min-height: 90vh;
  margin-top: 50px;
  margin-bottom: 50px;
}

.content-wrapper-width {
  width: 80% !important;
  max-width: 700px !important;
}

.main-title {
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: 2.3em;
  font-weight: 200;
  margin-top: 12%;
}

.main-title span {
  background-color: #b88766cc;
  padding: 4px 14px 4px 14px;
}

.text-accent {
  color: #558b2f;
}

a {
  color: #b27c58 !important;
}

#arrow_down {
  height: 30px;
}

/* Over Toermalijn */

.hour-timeline {
  margin-left: 10px;
}

/* Contact */

#contact {
  height: 100vh !important;
  background-color: white;
}

/* MOBILE */

@media only screen and (max-width: 600px) {
  .main-title {
    font-size: 1.1em !important;
    top: 18% !important;
    position: absolute;
    text-align: right;
  }

  .bottom-card-title {
    font-size: 1.1em !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    line-height: 1.3;
  }

  .bottom-card-wrapper {
    height: 40%;
  }

  #arrow_down {
    height: 15px;
  }

  p {
    font-size: 0.9em;
  }
}

/* TABLET */

@media only screen and (max-width: 950px) and (min-width: 600px) {
  .main-title {
    font-size: 1.7em !important;
    margin-top: 6%;
  }

  .main-bottom-card {
    width: 80% !important;
  }

  .bottom-card-wrapper {
    height: 40%;
  }
}

/* SMALL SCREEN */

@media only screen and (max-width: 1250px) and (min-width: 950px) {
  .main-bottom-card {
    width: 300px !important;
  }

  .main-title {
    font-size: 1.8em;
    margin-top: 8%;
  }
}

/* MEDIUM SCREEN */
@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .main-title {
    font-size: 1.8em;
    margin-top: 10%;
  }
}
</style>

<script>
import { gmapApi } from 'vue2-google-maps';

export default {
  name: 'Home',
  props: {
    source: String,
  },
  computed: {
    google: gmapApi,
  },
  data: () => ({
    drawer: false,
    timeline: [
      {
        hour: '8u00',
        icon: 'mdi-bicycle',
        text:
          'We brengen met de Grote Bakfiets Joren & Thorben naar school. Vroege kindjes mogen mee, anderen worden onderweg opgepikt. De late kindjes komen na 9u, als we terug zijn.',
      },
      {
        hour: '9u30',
        icon: 'mdi-fruit-cherries',
        text: 'We eten fruit. De kleintjes gaan slapen.',
      },
      {
        hour: '11u00',
        icon: 'mdi-silverware-spoon',
        text: 'Groentemaaltijd.',
      },
      {
        hour: '12u00',
        icon: 'mdi-sleep',
        text: 'Alle kinderen gaan slapen.',
      },
      {
        hour: '15u00',
        icon: 'mdi-food-apple',
        text: 'Tussendoortje / fruitpap.',
      },
      {
        hour: '16u00',
        icon: 'mdi-handball',
        text: 'Joren & Thorben ophalen / Buiten spelen.',
      },
    ],
  }),
};
</script>
