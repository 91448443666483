import Fotos from './components/Fotos.vue';
import Home from './components/Home.vue';
import Upload from './components/Upload.vue';

const routes = [
    {path: '/', component: Home},
    {path:'/fotos', component: Fotos},
    {path:'/upload', component: Upload},
];

export default routes;